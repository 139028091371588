<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="animals"
      :item-class="compileRowClasses"
      item-key="id"
      class="elevation-1"
      @click:row="gotoAnimal"
      sort-by="risk"
      :sort-desc="true"
    >
      <template v-slot:[`item.risk_early`]="{ item }">
        {{ item.risk_early ? item.risk_early / 10 : "" }}
      </template>

      <template v-slot:[`item.risk_100`]="{ item }">
        {{ item.risk_100 ? item.risk_100 / 10 : "" }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  name: "Warning",

  data: () => ({
    headers: [
      //{ text: "L-ID", value: "id" },
      { text: "Ohrmarke", value: "mark" },
      { text: "#", value: "counter" },
      { text: "Kalbedatum", value: "calving", width: 100 },
      { text: "Calcium [mmol/L]", value: "calcium", width: 120 },
      { text: "BHB [mmol/L]", value: "BHB", width: 120 },
      { text: "NEFA [µmol/L]", value: "NEFA", width: 120 },
      { text: "IGF-1 [ng/mL]", value: "IGF1", width: 120 },
      { text: "RUDI", value: "risk_early", width: 70 },
      { text: "RUDI (100)", value: "risk_100", width: 70 },
      { text: "Milchmenge [kg/Tag]", value: "milk_per_day", width: 140 },
      { text: "Fettgehalt [%]", value: "fat_content", width: 120 },
      { text: "Proteingehalt [%]", value: "protein_content", width: 120 },
      // { text: "Trockenstellung", value: "drying_off_date", width: 160 },
      // { text: "Besamung", value: "insemination_date", width: 160 },
      // { text: "BCS", value: "body_condition_score", width: 100 },
      { text: "Glukose [mmol/L]", value: "glucose", width: 120 },
      { text: "Insulin [µlU/ml]", value: "insulin", width: 100 },
    ],
  }),

  computed: {
    animals() {
      const mostRecentLacatations = this.lactations.reduce((acc, lac) => {
        if (acc[lac.animal_id] && lac.calving < acc[lac.animal_id].calving)
          return acc;
        return {
          ...acc,
          [lac.animal_id]: lac,
        };
      }, {});
      return Object.values(this.$store.getters["animals/all"])
        .map((a) => ({ ...a, ...mostRecentLacatations[a.id] }))
        .filter((a) => a.risk_early >= this.$config.thresholds[0] * 10);
    },
    lactations() {
      return Object.values(this.$store.getters["lactations/all"]);
    },
  },

  methods: {
    compileRowClasses(item) {
      if (item.risk_early == null) {
        return "";
      } else if (item.risk_early / 10 < this.$config.thresholds[0]) {
        return "animal__risk-green";
      } else if (item.risk_early / 10 < this.$config.thresholds[1]) {
        return "animal__risk-yellow";
      }
      return "animal__risk-red";
    },
    gotoAnimal(animal) {
      this.$router.push({ name: "Animal", params: { id: animal.animal_id } });
    },
  },
};
</script>

<style scoped lang="scss">
.v-data-table ::v-deep .v-data-table__mobile-table-row {
  &.animal__risk-green {
    display: block !important;
    border-left: 6px solid rgba(0, 255, 0, 0.7);
    background: transparent !important;
  }

  &.animal__risk-yellow {
    display: block !important;
    border-left: 6px solid rgba(255, 255, 0, 0.7);
    background: transparent !important;
  }

  &.animal__risk-red {
    display: block !important;
    border-left: 6px solid rgba(255, 0, 0, 0.7);
    background: transparent !important;
  }

  & > .v-data-table__mobile-row:last-child {
    border-width: 2px !important;
    border-color: rgba(0, 0, 0, 0.5) !important;
  }
}

.v-data-table {
  &::v-deep .animal__risk-green {
    background-color: rgba(0, 255, 0, 0.2);
  }
  &::v-deep .animal__risk-yellow {
    background-color: rgba(255, 255, 0, 0.2);
  }
  &::v-deep .animal__risk-red {
    background-color: rgba(255, 0, 0, 0.2);
  }
}
</style>
