var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{attrs:{"color":"success","elevation":"0"},on:{"click":function($event){_vm.$importer.mainDialog = true}}},[_vm._v("importieren")]),_c('v-data-table',{staticStyle:{"position":"relative"},attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.imports,"options":_vm.options,"server-items-length":_vm.totalImports},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.email)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$date.format(item.created_at, "dd.MM.yyyy HH:ii"))+" ")]}},{key:"item.statistics",fn:function(ref){
var item = ref.item;
return [_vm._l((item.statistics),function(stat,key){return [(key != 'error')?_c('span',{key:((item.id) + "-" + key)},[_vm._v(" ( "+_vm._s(stat.imported.length)+" / "+_vm._s(stat.imported.length + stat.unimported.length)+" ) ")]):_vm._e()]})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.statistics)?[(item.statistics.error)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")]):(item.processing_finished_at !== null)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-bold")]):_vm._e()]:_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success","icon":""},on:{"click":function($event){return _vm.api.download(item.id)}}},[_c('v-icon',[_vm._v(" mdi-download ")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }