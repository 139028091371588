<template>
  <v-form ref="form">
    <v-container fluid>
      <v-row>
        <v-col
          class="text-left mx-3 px-0 mb-5 pb-0"
          style="border-bottom: 2px solid #AAA;"
        >
          <span class="text-h5">Tierdaten</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-text-field
            autocomplete="off"
            label="Ohrmarke"
            name="optikuh2-mark"
            :rules="[v => !!v || 'Wert eintragen!']"
            v-model="animal.mark"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-text-field
            autocomplete="off"
            label="Name / Bezeichnung"
            name="optikuh2-name"
            v-model="animal.name"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-menu
            ref="birth_date"
            v-model="menuDate"
            :close-on-content-click="false"
            :return-value.sync="animal.birth_date"
            min-width="290px"
            offset-y
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                autocomplete="off"
                label="Geburtsdatum"
                prepend-icon="mdi-calendar"
                readonly
                :rules="[v => !!v || 'Wert eintragen!']"
                v-bind="attrs"
                v-model="animal.birth_date"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :allowedDates="val => val <= today"
              v-model="animal.birth_date"
              no-title
              scrollable
              :show-current="false"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuDate = false"
                >Cancel</v-btn
              >
              <v-btn
                text
                color="primary"
                @click="$refs.birth_date.save(animal.birth_date)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-text-field
            label="Rasse"
            name="optikuh2-race"
            v-model="animal.race"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-text-field
            autocomplete="off"
            label="Bes. Kennzeichen"
            name="optikuh2-special_marks"
            v-model="animal.special_marks"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-btn @click="save()" color="success">
            Speichern
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      absolute
      bottom
      color="success"
      v-model="dialog"
      transition="slide-y-transition"
      :timeout="2000"
      width="300"
    >
      Tier wurde gespeichert.
    </v-snackbar>
  </v-form>
</template>

<script>
import { animals } from "@/api";

export default {
  name: "Animals_Create",

  props: {
    animal: {
      type: Object
    }
  },

  data: () => ({
    dialog: false,
    menuDate: false
  }),

  computed: {
    companyId() {
      return this.$store.getters["company/company"].id;
    },
    today() {
      return new Date().toISOString().slice(0, 10);
    }
  },

  methods: {
    save() {
      if (!this.$refs.form.validate()) return;
      if (this.animal.company_id) {
        animals.edit(this.animal.id, this.animal).then(response => {
          this.$emit("animal-added", response.data);
          this.dialog = true;
        });
      } else {
        animals.store(this.companyId, this.animal).then(response => {
          this.$emit("animal-added", response.data);
          this.dialog = true;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-data-table
  ::v-deep
  .v-data-table__mobile-table-row
  > .v-data-table__mobile-row:last-child {
  border-width: 2px !important;
  border-color: rgba(0, 0, 0, 0.5) !important;
}
</style>
