<template>
  <div>
    <v-dialog
      v-model="$importer.mainDialog"
      width="min(100vw, 400px)"
    >
      <v-card>
        <v-toolbar color="primary" dark flat>
          <v-btn
            v-if="false"
            class="mr-3"
            x-small
            dark
            fab
            @click="dialog = false"
            color="error"
            :elevation="0"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Datenimport</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <!-- <v-btn class="mx-2" @click="submit()" color="success" :elevation="0"
              >Speichern</v-btn
            >
            <v-btn class="mx-2" @click="reset()" color="warning" :elevation="0"
              >Zurücksetzen</v-btn
            > -->
            <v-btn
              class="mx-2"
              @click="$importer.mainDialog = false"
              color="error"
              :elevation="0"
              >Schließen</v-btn
            >
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-file-input @change="selectFile" ref="fileInput" />
          <v-btn @click="upload()" color="success">Upload</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <progress-dialog
      :key="importInProgress.id"
      :importInProgress="importInProgress"
      v-for="(importInProgress, idx) in importsInProgress"
      :style="{ marginBottom: `${idx * 80}px`, transition: 'margin-bottom 500ms ease-in' }"
    />
  </div>
</template>

<script>
import { imports as api } from "@/api";
import ProgressDialog from "./ProgressDialog.vue";

export default {
  name: "Import_Main_Dialog",

  components: { ProgressDialog },

  data: () => ({
    file: null
  }),

  computed: {
    importsInProgress() {
      return this.$importer.imports;
    }
  },

  methods: {
    generateUUID() {
      let d = new Date().getTime(),
        d2 = (performance && performance.now && performance.now() * 1000) || 0;
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
        let r = Math.random() * 16;
        if (d > 0) {
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c == "x" ? r : (r & 0x7) | 0x8).toString(16);
      });
    },
    onUploadProgress(ev, importData) {
      if (ev.lengthComputable) {
        const progress = Math.round((ev.loaded * 100) / ev.total);
        this.$importer.update(importData.id, { progress });
      }
    },
    selectFile(file) {
      this.file = file;
    },
    upload() {
      if (!this.file) return;

      const formData = new FormData();

      formData.append("document", this.file);

      const newImport = {
        id: this.generateUUID(),
        progress: 0
      };

      this.$importer.add(newImport);

      this.uploading = true;

      api
        .store(formData, {
          onUploadProgress: event => this.onUploadProgress(event, newImport)
        })
        .then(response => {
          this.$importer.update(newImport.id, response.data);
        })
        .finally(() => {
          this.uploading = false;
          this.$refs.fileInput.reset();
          this.file = null;
        });
    }
  }
};
</script>
