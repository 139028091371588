import Vue from "vue";

import App from "./App.vue";
// import "./registerServiceWorker";

import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

import config from "./config";
import router from "./router";
import store from "./store";
import date from "./plugins/date";
import emitter from "./plugins/emitter";
import importer from "./plugins/imports";
import toast from "./plugins/toast";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

Vue.prototype.$config = config;

Vue.use(date);
Vue.use(emitter);
Vue.use(importer);
Vue.use(toast);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
