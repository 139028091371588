import { http } from "./http";

const res = "companies";

const init = () => {
  return http.get(`/`);
};

const update = data => {
  // Method spoofing to be sure
  return http.post(`${res}`, {...data, '_method': 'PATCH'});
};

export const company = {
  init,
  update
};
