<template>
  <div v-if="document" style="word-break: break-word; text-align: left;">
    <div
      v-html="document.text"
    />
    Stand: {{ document.date }}
  </div>
  <div v-else />
</template>

<script>
import { documents } from "@/api";

export default {
  name: "Documents_Legal_Privacy",

  data: () => ({
    document: null,
  }),

  created() {
    documents.get("privacy").then((response) => {
      this.document = response.data;
    });
  },
};
</script>
