const defaultState = () => ({
  bottomNav: {
    show: false,
    values: [],
    value: 0,
  },
});

const mutations = {
  hideBottomNav(state) {
    state.bottomNav.show = false;
  },
  setBottomNavValue(state, value) {
    state.bottomNav.value = value;
  },
  setBottomNavValues(state, values) {
    state.bottomNav.values = values;
  },
  showBottomNav(state) {
    state.bottomNav.show = true;
  },
};

const getters = {
  bottomNavShow(state) {
    return state.bottomNav.show;
  },
  bottomNavValue(state) {
    return state.bottomNav.value;
  },
  bottomNavValues(state) {
    return state.bottomNav.values;
  },
};

export const app = {
  namespaced: true,
  state: defaultState(),
  mutations,
  getters,
};
