<template>
  <v-dialog :value="value" width="600">
    <v-card>
      <v-card-title>
        <p class="headline" style="width: 100%;">Analyse</p>
        <p>
          * Notwendige Felder um den RuDI zu berechnen
        </p>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col :cols="4">
                <v-date-picker-menu
                  format="yyyy.MM.dd"
                  label="Datum*"
                  :rules="[(v) => !!v || 'Wert eintragen!']"
                  v-model="state.date"
                />
              </v-col>
              <v-col :cols="4" :key="field" v-for="field in rudiFields">
                <v-text-field
                  :name="field"
                  :label="labels[field]"
                  v-model="state[field]"
                  :rules="[
                    (v) =>
                      !v ||
                      (v >= $config.validation[field].min &&
                        v <= $config.validation[field].max) ||
                      `Der Wert muss zwischen ${$config.validation[field].min} und ${$config.validation[field].max} liegen`,
                  ]"
                />
              </v-col>
            </v-row>
            <v-divider />
            <v-row>
              <v-col :cols="4" :key="field" v-for="field in stateFields">
                <v-text-field
                  :name="field"
                  :label="labels[field]"
                  v-model="state[field]"
                  :rules="[
                    (v) =>
                      !v ||
                      (v >= $config.validation[field].min &&
                        v <= $config.validation[field].max) ||
                      `Der Wert muss zwischen ${$config.validation[field].min} und ${$config.validation[field].max} liegen`,
                  ]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-5 px-5">
        <v-btn color="warning" @click="$emit('input', false)">
          Schließen
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="saveState()">
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VDatePickerMenu from "./VDatePickerMenu.vue";

export default {
  name: "State_Dialog",

  components: {
    VDatePickerMenu,
  },

  props: {
    input: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    labels: {
      date: "Datum*",
      calcium: "Calcium* [mmol/L]",
      BHB: "BHB* [mmol/L]",
      NEFA: "NEFA* [µmol/L]",
      IGF1: "IGF-1* [ng/mL]",
      milk_per_day: "Milchmenge [kg/Tag]",
      fat_content: "Fettgehalt [%]",
      protein_content: "Proteingehalt [%]",
      drying_off_date: "Trockenstellung",
      insemination_date: "Besamung",
      body_condition_score: "BCS",
      glucose: "Glukose [mmol/L]",
      insulin: "Insulin [µlU/ml]",
    },
    rudiFields: ["calcium", "BHB", "NEFA", "IGF1"],
    state: {},
    stateFields: [
      "milk_per_day",
      "fat_content",
      "protein_content",
      "body_condition_score",
      "glucose",
      "insulin",
    ],
  }),

  watch: {
    input: {
      handler: function(val) {
        this.state = { ...val };
      },
      deep: true,
    },
  },

  methods: {
    saveState() {
      if (!this.$refs.form.validate()) return;
      this.$emit("save", this.state);
    },
  },
};
</script>
