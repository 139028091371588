<template>
  <v-container  class="pa-0" fluid>
    <v-form @submit.prevent="login()">
      <v-card max-width="350" style="margin: auto">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Anmelden</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            name="optikuh2-email"
            label="Email"
            v-model="email"
            :rules="rules.email"
            @keydown.enter="login()"
          ></v-text-field
          ><br />
          <v-text-field
            label="Passwort"
            type="password"
            v-model="password"
            :rules="rules.password"
            @keydown.enter="login()"
          ></v-text-field
          ><br />
          <v-btn color="primary" small style="margin-top: -3em; float: right;" text to="ResetPassword">Vergessen?</v-btn><br>
          <v-btn class="mb-5 mt-5" color="success" style="clear: both;" @click="login()">Login</v-btn><br>
          <v-btn class="mt-5" text color="primary" to="Register">Registrieren</v-btn>
        </v-card-text>
      </v-card>
    </v-form>
    <v-snackbar
      absolute
      bottom
      color="error"
      v-model="errorDialog"
      transition="slide-y-transition"
      width="300"
    >
      Fehler beim Anmelden. Passwort oder Email sind falsch.
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "Login",

  data: () => ({
    email: "",
    errorDialog: false,
    password: "",

    rules: {
      email: [
        (v) => !!v || "E-Mail eingeben",
        (v) => /.+@.+\..+/.test(v) || "Korrekte E-Mail eingeben",
      ],
      password: [
        (v) => !!v || "Passwort eingeben",
        (v) =>
          (v && v.length >= 8) || "Das Passwort muss mind. 8 Zeichen enthalten",
      ],
    }
  }),

  methods: {
    login() {
      this.$store
        .dispatch("auth/login", { email: this.email, password: this.password })
        .then(() => {
          // this.$store.dispatch("companies/init");
          this.$store.dispatch("animals/init");
          this.$store.dispatch("lactations/init");
          this.$router.push({ name: "Home" });
        })
        .catch((error) => {
          if (error) {
            this.errorDialog = true;
            setTimeout(() => (this.errorDialog = false), 3000);
          }
          throw error;
        });
    },
  },
};
</script>
