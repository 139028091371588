import Toast from "./ToastComponent.vue";
import { emitter } from "./emitter";

export default {
  install(vue, args = {}) {
    this.params = args;

    vue.component("plugin-toast", Toast);

    const notify = (params) => {
      if (typeof params === "string") {
        return emitter.emit("plugin::toast-show", { text: params });
      }
      return emitter.emit("plugin::toast-show", params);
    };

    notify.close = () => {
      emitter.emit("plugin::toast-close");
    };

    notify.error = (text) => {
      return emitter.emit("plugin::toast-show", { text, timeout: 5000, color: "error" });
    };

    vue.prototype.$toast = notify;
  },
};
