import Vue from "vue";
import Vuetify from "vuetify/lib";
import de from "vuetify/es5/locale/de";

Vue.use(Vuetify);

import Udder from "@/components/icons/Udder.vue";

export default new Vuetify({
  dark: true,
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#005133",
        secondary: "##9BC34B",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  icons: {
    values: {
      udder: {
        // name of our custom icon
        component: Udder, // our custom component
      },
    },
  },
  lang: {
    locales: { de },
    current: "de",
  },
});
