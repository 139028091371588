import { http } from "./http";

const res = "documents";

const get = (type) => {
  return http.get(`/${res}/${type}`);
};

export const documents = {
  get,
};
