import MainDialog from "./MainDialog";

export default {
  imports: [],

  install(Vue) {
    Vue.component("importer-main-dialog", MainDialog);

    const add = data => {
      this.imports.push(data);
    };

    const remove = id => {
      const idx = this.imports.findIndex(i => i.id == id);
      if (idx > -1) {
        this.imports.splice(idx, 1);
      }
    };

    const update = (id, data) => {
      const idx = this.imports.findIndex(i => i.id == id);
      if (idx > -1) {
        this.imports.splice(idx, 1, { ...this.imports[idx], ...data });
      }
    };

    Vue.prototype.$importer = Vue.observable({
      add,
      imports: this.imports, // list of active imports (either uploading or processing)
      mainDialog: false,
      remove,
      update
    });
  }
};
