import {
  format,
} from "date-fns";

export default {
  install(Vue) {
    Vue.prototype.$date = {
      format: (date, form) => format(new Date(date), form),
    };
  },
};
