import { animals as api } from "@/api";

const defaultState = () => ({
  entities: {},
  ids: [],
});

const actions = {
  init({ commit }) {
    api
      .all()
      .then((response) => {
        commit("setState", response.data);
      })
      .catch((err) => {
        console.error(err.response);
      });
  },
  add({ commit }, animal) {
    commit("addAnimal", animal);
  },
  edit({ commit }, animal) {
    api.edit(animal).then((response) => {
      const data = response.data;
      commit("editAnimal", data);
    });
  },
};

const mutations = {
  addAnimal(state, animal) {
    state.entities[animal.id] = { ...animal };
    state.ids = [...state.ids, animal.id];
  },
  editAnimal(state, animal) {
    state.entities[animal.id] = { ...animal };
  },
  setState(state, data) {
    state.entities = Object.values(data).reduce(
      (acc, obj) => ({ ...acc, [obj.id]: obj }),
      {}
    );
    state.ids = Object.values(data).map((a) => a.id);
  },
  resetState(state) {
    Object.assign(state, defaultState());
  },
};

const getters = {
  all(state) {
    return state.entities;
  },
};

export const animals = {
  namespaced: true,
  state: defaultState(),
  actions,
  mutations,
  getters,
};
