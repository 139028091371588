<template>
  <v-snackbar
    bottom
    :color="progress === 100 ? 'green' : undefined"
    right
    :timeout="-1"
    :value="true"
  >
    {{ uploadFinished ? "Verarbeitung der Daten" : "Hochladen der Datei" }}
    <br />
    <v-progress-linear
      :color="uploadFinished ? 'green' : 'blue'"
      :value="progress"
    />
  </v-snackbar>
</template>

<script>
import { imports as api } from "@/api";

export default {
  name: "Import_Progress_Dialog",

  props: {
    importInProgress: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    progress: 0,
    uploadFinished: false
  }),

  watch: {
    "importInProgress.progress": function(val) {
      this.progress = val;
    }
  },

  mounted() {
    if (!String(this.importInProgress.id).includes("-")) {
      this.uploadFinished = true;
      // start interval ajax requests to gather import upload information
      this.watchImportProgress();
    }
  },

  methods: {
    watchImportProgress() {
      api.show(this.importInProgress.id).then(response => {
        console.log(response.data.id, response.data);
        this.$importer.update(response.data.id, response.data);
        // If the processing of the file has finished => stop watching
        if (response.data.processing_finished_at !== null) {
          this.progress = 100;
          this.$root.$emit('importer::importFinished');
          return setTimeout(
            () => this.$importer.remove(response.data.id),
            2000
          );
        }
        this.progress = response.data.process
          ? Math.round(
              (response.data.process * 100) / response.data.max_process
            )
          : 0;
        setTimeout(() => this.watchImportProgress(), 2000);
      });
    }
  }
};
</script>
