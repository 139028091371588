<template>
  <div>
    <v-row>
      <v-col>
        <v-btn
          @click="$router.push({ name: 'Animals_Create' })"
          color="success"
        >
          Neues Tier eintragen
        </v-btn>
      </v-col>
      <v-col class="flex-grow-0">
        <v-dialog v-model="helpDialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              elevation="0"
              fab
              v-bind="attrs"
              v-on="on"
              x-small
            >
              <v-icon>
                mdi-help
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5 lighten-2">
              RuDI
            </v-card-title>

            <v-card-text style="text-align: left;">
              RuDI ist ein Risiko- und Datenindex zur Einschätzung des Ketoserisikos.<br>
              Bei RuDI ≥ 6,7 weist ein klinisch gesundes Tier ein hohes Risiko auf, eine klinische oder subklinische Ketose zu entwickeln.<br>
              Die Risikobewertung wird optisch wie folgt dargestellt:<br>
              rot = hohes Risiko<br>
              grün = niedriges Risiko<br>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="helpDialog = false">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1"
      @click:row="gotoAnimal"
      :headers="headers"
      :item-class="compileRowClasses"
      item-key="id"
      :items="animals"
      :search="search"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Filter"
          class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:[`item.risk_early`]="{ item }">
        <span v-if="item.risk_early">
          {{ item.risk_early / 10 }}
        </span>
        <v-btn
          color="success"
          @click.stop.prevent="openModal(item)"
          v-else-if="item.latest_lactation_id"
        >
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.risk_100`]="{ item }">
        {{ item.risk_100 ? item.risk_100 / 10 : "" }}
      </template>
    </v-data-table>
    <state-dialog :input="state" @save="saveState" v-model="stateDialog" />
  </div>
</template>

<script>
import { states } from "@/api";
import StateDialog from "@/components/StateDialog.vue";
import difference from "@/utils/difference";

export default {
  name: "Animals",

  components: { StateDialog },

  data: () => ({
    headers: [
      // { text: "T-ID", value: "id" },
      { text: "Ohrmarke", value: "mark", width: 200 },
      { text: "Name", value: "name" },
      { text: "Geburtdatum", value: "birth_date", width: 130 },
      { text: "Rasse", value: "race" },
      { text: "Bes. Kennzeichen", value: "special_marks" },
      { text: "RuDI", value: "risk_early", width: 120 },
      { text: "RuDI (100)", value: "risk_100", width: 120 },
    ],
    helpDialog: false,
    search: "",
    state: {},
    stateOriginal: {},
    stateDialog: false,
  }),

  computed: {
    animals() {
      const mostRecentLacatations = this.lactations.reduce((acc, lac) => {
        if (acc[lac.animal_id] && lac.calving < acc[lac.animal_id].calving)
          return acc;
        return {
          ...acc,
          [lac.animal_id]: lac,
        };
      }, {});
      return Object.values(this.$store.getters["animals/all"]).map((a) => ({
        ...mostRecentLacatations[a.id],
        ...a,
        latest_lactation_id: mostRecentLacatations[a.id]
          ? mostRecentLacatations[a.id].id
          : null,
      }));
    },
    lactations() {
      return Object.values(this.$store.getters["lactations/all"]);
    },
  },

  methods: {
    compileRowClasses(item) {
      if (item.risk_early == null) {
        return "";
      } else if (item.risk_early / 10 < this.$config.thresholds[0]) {
        return "animal__risk-green";
      } else if (item.risk_early / 10 < this.$config.thresholds[1]) {
        return "animal__risk-yellow";
      }
      return "animal__risk-red";
    },
    gotoAnimal(animal) {
      this.$router.push({ name: "Animal", params: { id: animal.id } });
    },
    openModal(item) {
      this.$set(this, "state", { ...item });
      this.$set(this, "stateOriginal", { ...item });
      this.stateDialog = true;
    },
    saveState(formData) {
      const data = {
        ...difference(formData, this.stateOriginal),
        lactation_id: this.lactation.id,
      };
      if (this.state.id) {
        return states.edit(this.state.id, data).then((response) => {
          this.stateDialog = false;
          this.$toast("Zustand aktualisiert");
          this.states = this.states.map((s) => {
            if (s.id != response.data.id) return s;
            return response.data;
          });
        });
      }
      states.store(data).then((response) => {
        this.states = [...this.states, response.data];
        this.$toast("Zustand gespeichert");
        this.stateDialog = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table ::v-deep .v-data-table__mobile-table-row {
  &.animal__risk-green {
    display: block !important;
    border-left: 6px solid rgba(0, 255, 0, 0.7);
    background: transparent !important;
  }

  &.animal__risk-yellow {
    display: block !important;
    border-left: 6px solid rgba(255, 255, 0, 0.7);
    background: transparent !important;
  }

  &.animal__risk-red {
    display: block !important;
    border-left: 6px solid rgba(255, 0, 0, 0.7);
    background: transparent !important;
  }

  & > .v-data-table__mobile-row:last-child {
    border-width: 2px !important;
    border-color: rgba(0, 0, 0, 0.5) !important;
  }
}

.v-data-table {
  &::v-deep .animal__risk-green {
    background-color: rgba(0, 255, 0, 0.2) !important;
  }
  &::v-deep .animal__risk-yellow {
    background-color: rgba(255, 255, 0, 0.2) !important;
  }
  &::v-deep .animal__risk-red {
    background-color: rgba(255, 0, 0, 0.2) !important;
  }
}
</style>
