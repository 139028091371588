<template>
  <v-form ref="form">
    <v-container fluid>
      <v-row>
        <v-col
          class="text-left mx-3 px-0 mb-5 pb-0"
          style="border-bottom: 2px solid #AAA;"
        >
          <span class="text-h5">Tierdaten</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-text-field
            autocomplete="off"
            label="Ohrmarke *"
            name="optikuh2-mark"
            :rules="[(v) => !!v || 'Wert eintragen!']"
            v-model="animal.mark"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-text-field
            autocomplete="off"
            label="Name / Bezeichnung"
            name="optikuh2-name"
            v-model="animal.name"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-date-picker-menu
            :allowedDates="(val) => val <= today"
            format="dd.MM.yyyy"
            label="Geburtsdatum"
            prepend-icon="mdi-calendar"
            :rules="[v => !!v || 'Wert eintragen!']"
            v-model="animal.birth_date"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-text-field
            label="Rasse"
            name="optikuh2-race"
            v-model="animal.race"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-text-field
            autocomplete="off"
            label="Bes. Kennzeichen"
            name="optikuh2-special_marks"
            v-model="animal.special_marks"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-btn @click="save()" color="success">
            Speichern
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      absolute
      bottom
      color="success"
      v-model="dialog"
      transition="slide-y-transition"
      :timeout="2000"
      width="300"
    >
      Tier wurde erstellt.
    </v-snackbar>
  </v-form>
</template>

<script>
import { animals } from "@/api";
import VDatePickerMenu from "@/components/VDatePickerMenu.vue";

export default {
  name: "Animals_Create",

  components: {
    VDatePickerMenu,
  },

  data: () => ({
    animal: {},
    dialog: false,
    menuDate: false,
  }),

  computed: {
    today() {
      return new Date().toISOString().slice(0, 10);
    },
  },

  methods: {
    save() {
      if (!this.$refs.form.validate()) return;
      animals.store(this.animal).then((response) => {
        this.$store.dispatch("animals/add", response.data);
        this.$refs.form.reset();
        this.dialog = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table
  ::v-deep
  .v-data-table__mobile-table-row
  > .v-data-table__mobile-row:last-child {
  border-width: 2px !important;
  border-color: rgba(0, 0, 0, 0.5) !important;
}
</style>
