import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/** Default config for axios instance */
export const config = {
  baseURL: "/api/"
};

/** Creating the instance for axios */
const http = axios.create(config);

http.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export { http };
