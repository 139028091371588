<template>
  <v-container  class="pa-0" fluid>
    <v-form @submit.prevent="reset()">
      <v-card max-width="350" style="margin: auto">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Passwort zurücksetzen</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            name="optikuh2-email"
            label="Email"
            :rules="emailRules"
            v-model="email"
            @keydown.enter="reset()"
          ></v-text-field>
          <v-btn
            class="mb-5 mt-5"
            color="success"
            style="clear: both;"
            @click="reset()"
            >zurücksetzen</v-btn
          ><br />
        </v-card-text>
      </v-card>
    </v-form>
    <v-snackbar
      absolute
      bottom
      color="error"
      v-model="errorDialog"
      transition="slide-y-transition"
      width="300"
    >
      Fehler beim Zurücksetzen.
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "ResetPassword",

  data: () => ({
    email: "",
    emailRules: [
      (v) => !!v || "E-Mail eingeben",
      (v) => /.+@.+\..+/.test(v) || "Korrekte E-Mail eingeben",
    ],
    errorDialog: false,
  }),

  methods: {
    reset() {
      this.$store
        .dispatch("auth/reset", { email: this.email })
        .then(() => {
          this.$router.push({ name: "Login" });
        })
        .catch((error) => {
          if (error) {
            this.errorDialog = true;
            // setTimeout(() => (this.errorDialog = false), 3000);
          }
        });
    },
  },
};
</script>
