<template>
  <v-container>
    <v-row v-if="company.information.length === 0 && false">
      <v-alert
        border="left"
        color="red"
        type="error"
        @click.native="$router.push({ name: 'Company' })"
        >Bitte geben Sie die Leistungsdaten Ihres Betriebes hier an!</v-alert
      >
    </v-row>
    <v-row>
      <v-col :cols="6" :sm="6">
        <v-card class="mx-auto" dark max-width="700" :to="{ name: 'Company' }">
          <v-img
            class="white--text align-end"
            height="35vh"
            :src="require('@/assets/company_opt.jpg')"
          >
            <v-card-title class="home__vcard-title">Betrieb</v-card-title>
          </v-img>
        </v-card>
      </v-col>
      <v-col :cols="6" :sm="6">
        <v-card class="mx-auto" dark max-width="700" :to="{ name: 'Animals' }">
          <v-img
            class="white--text align-end"
            height="35vh"
            :src="require('@/assets/cow_opt.jpg')"
          >
            <v-card-title class="home__vcard-title">Tiere</v-card-title>
          </v-img>
        </v-card>
      </v-col>
      <v-col :cols="6" :sm="6">
        <v-card class="mx-auto" dark max-width="700" :to="{ name: 'Warning' }">
          <v-img
            class="white--text align-end"
            height="35vh"
            :src="require('@/assets/warning_opt.jpg')"
          >
            <v-card-title class="home__vcard-title">Warnung</v-card-title>
          </v-img>
        </v-card>
      </v-col>
      <v-col :cols="6" :sm="6">
        <v-card
          class="mx-auto"
          dark
          max-width="700"
          :to="{ name: 'Import' }"
        >
          <v-img
            class="white--text align-end"
            height="35vh"
            :src="require('@/assets/herde-fs8.png')"
          >
            <v-card-title class="home__vcard-title" style="word-break: break-word;">Herde Import</v-card-title>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",

  computed: {
    company() {
      return this.$store.getters["company/company"];
    },
  },
};
</script>

<style>
.home__vcard-title {
  text-align: center;
  display: block !important;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.9) 20%,
    rgba(0, 0, 0, 0) 100%
  );
  padding-bottom: 0 !important;
  padding-top: 10vh !important;
  font-size: 3vh !important;
  letter-spacing: 0.1em;
}
</style>
