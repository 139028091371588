var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.value,"width":"600"}},[_c('v-card',[_c('v-card-title',[_c('p',{staticClass:"headline",staticStyle:{"width":"100%"}},[_vm._v("Analyse")]),_c('p',[_vm._v(" * Notwendige Felder um den RuDI zu berechnen ")])]),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":4}},[_c('v-date-picker-menu',{attrs:{"format":"yyyy.MM.dd","label":"Datum*","rules":[function (v) { return !!v || 'Wert eintragen!'; }]},model:{value:(_vm.state.date),callback:function ($$v) {_vm.$set(_vm.state, "date", $$v)},expression:"state.date"}})],1),_vm._l((_vm.rudiFields),function(field){return _c('v-col',{key:field,attrs:{"cols":4}},[_c('v-text-field',{attrs:{"name":field,"label":_vm.labels[field],"rules":[
                  function (v) { return !v ||
                    (v >= _vm.$config.validation[field].min &&
                      v <= _vm.$config.validation[field].max) ||
                    ("Der Wert muss zwischen " + (_vm.$config.validation[field].min) + " und " + (_vm.$config.validation[field].max) + " liegen"); } ]},model:{value:(_vm.state[field]),callback:function ($$v) {_vm.$set(_vm.state, field, $$v)},expression:"state[field]"}})],1)})],2),_c('v-divider'),_c('v-row',_vm._l((_vm.stateFields),function(field){return _c('v-col',{key:field,attrs:{"cols":4}},[_c('v-text-field',{attrs:{"name":field,"label":_vm.labels[field],"rules":[
                  function (v) { return !v ||
                    (v >= _vm.$config.validation[field].min &&
                      v <= _vm.$config.validation[field].max) ||
                    ("Der Wert muss zwischen " + (_vm.$config.validation[field].min) + " und " + (_vm.$config.validation[field].max) + " liegen"); } ]},model:{value:(_vm.state[field]),callback:function ($$v) {_vm.$set(_vm.state, field, $$v)},expression:"state[field]"}})],1)}),1)],1)],1)],1),_c('v-card-actions',{staticClass:"pb-5 px-5"},[_c('v-btn',{attrs:{"color":"warning"},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(" Schließen ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.saveState()}}},[_vm._v(" Speichern ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }