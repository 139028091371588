import { http } from "./http";

const res = "imports";

const all = query => {
  return http.get(`/${res}`, { params: query });
};

const download = id => {
  return http
    .get(`${res}/download/${id}`, {
      responseType: "blob"
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = response.headers["content-disposition"].match(
        /filename="?(.*)"?/
      )[1];
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

const show = id => {
  return http.get(`/${res}/${id}`);
};

const store = (data, config = {}) => {
  return http.post(`/${res}`, data, config);
};

export const imports = {
  all,
  download,
  show,
  store
};
