import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import transform from "lodash/transform";

export default function(object, base) {
  function changes(object, base) {
      return transform(object, function(result, value, key) {
          if (!isEqual(value, base[key])) {
              result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
          }
      });
  }
  return changes(object, base);
}