import { company as api } from "@/api";
import Vue from "vue";

const defaultState = () => ({
  name: "",
  street: "",
  zip: "",
  city: "",

  information: [],
});

const actions = {
  init({ commit }) {
    api
      .init()
      .then((response) => {
        commit("setCompany", response.data);
      })
      .catch((err) => {
        console.error(err.response);
      });
  },
  update({ commit }, data) {
    return api.update(data).then((response) => {
      if (data.type === "information") {
        commit("insertInformation", response.data.information[0]);
      } else if (data.type === "company") {
        commit("updateCompany", response.data);
      } else {
        commit("auth/userUpdate", response.data, { root: true });
      }
      return response.data;
    });
  },
};

const mutations = {
  insertInformation(state, data) {
    state.information = [data, ...state.information];
  },
  resetState(state) {
    Object.assign(state, defaultState());
  },
  setCompany(state, data) {
    Object.keys(defaultState()).map((key) => Vue.set(state, key, data[key]));
  },
  updateCompany(state, data) {
    ["name", "street", "zip", "city"].map((key) =>
      Vue.set(state, key, data[key])
    );
  },
};

const getters = {
  company(state) {
    return state;
  },
};

export const company = {
  namespaced: true,
  state: defaultState(),
  actions,
  mutations,
  getters,
};
