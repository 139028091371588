<template>
  <v-container>
    <v-data-table
      :item-class="compileRowClasses"
      :headers="headers"
      :items="sortedStates"
      item-key="id"
      class="elevation-1"
      @click:row="gotoState"
      style="position: relative;"
    >
      <template v-slot:[`item.date`]="{ item }">
        {{
          item.date
            .split("-")
            .reverse()
            .join(".")
        }}
      </template>

      <template v-slot:[`item.risk`]="{ item }">
        {{ item.risk ? item.risk / 10 : "" }}
      </template>

      <template v-slot:[`item.risk_100`]="{ item }">
        {{ item.risk_100 ? item.risk_100 / 10 : "" }}
      </template>

      <template v-slot:footer>
        <v-btn
          @click="showNewStateDialog()"
          color="success"
          style="position: absolute; bottom: 10px; left: 10px;"
        >
          Neue Analyse eintragen
        </v-btn>
      </template>
    </v-data-table>
    <state-dialog @save="saveState" :input="state" v-model="stateDialog" />
  </v-container>
</template>

<script>
import { states } from "@/api";
import StateDialog from "@/components/StateDialog.vue";
import difference from "@/utils/difference";

export default {
  name: "Lactation",

  components: { StateDialog },

  data: () => ({
    headers: [
      //{ text: "L-ID", value: "id" },
      // { text: "Ohrmarke", value: "animal.mark" },
      // { text: "#", value: "counter" },
      // { text: "Abkalbung", value: "calving", width: 160 },
      { text: "Datum", value: "date", width: 160 },
      { text: "Calcium [mmol/L]", value: "calcium", width: 120 },
      { text: "BHB [mmol/L]", value: "BHB", width: 120 },
      { text: "NEFA [µmol/L]", value: "NEFA", width: 120 },
      { text: "IGF-1 [ng/mL]", value: "IGF1", width: 120 },
      { text: "RUDI", value: "risk", width: 70 },
      { text: "Milchmenge [kg/Tag]", value: "milk_per_day", width: 140 },
      { text: "Fettgehalt [%]", value: "fat_content", width: 120 },
      { text: "Proteingehalt [%]", value: "protein_content", width: 120 },
      // { text: "Trockenstellung", value: "drying_off_date", width: 160 },
      // { text: "Besamung", value: "insemination_date", width: 160 },
      { text: "BCS", value: "body_condition_score", width: 100 },
      { text: "Glukose [mmol/L]", value: "glucose", width: 120 },
      { text: "Insulin [µlU/ml]", value: "insulin", width: 100 },
    ],
    menu: {},
    state: {},
    stateOriginal: {},
    states: [],
    stateDialog: false,
  }),

  computed: {
    animal() {
      return this.$store.getters["animals/all"].find(
        (a) => a.id == this.lactation.animal_id
      );
    },
    lactation() {
      return this.$store.getters["lactations/all"][this.$route.params.id];
    },
    sortedStates() {
      return this.states.slice().sort((a, b) => b.date.localeCompare(a.date));
    },
    stateFields() {
      return this.headers
        .map((h) => h.value)
        .filter(
          (v) =>
            ![
              "id",
              "animal.mark",
              "counter",
              "calving",
              "date",
              "drying_off_date",
              "insemination_date",
              "risk",
            ].includes(v)
        );
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const id = vm.$route.params.id;
      if (id) {
        states.show(id).then((response) => (vm.states = response.data));
        return;
      }
    });
  },

  methods: {
    compileRowClasses(item) {
      // const risk = this.calculateRisk(item);

      if (item.risk == null) {
        return "";
      } else if (item.risk / 10 < this.$config.thresholds[0]) {
        return "animal__risk-green";
      } else if (item.risk / 10 < this.$config.thresholds[1]) {
        return "animal__risk-yellow";
      }
      return "animal__risk-red";
    },
    gotoState(item) {
      this.$set(this, "state", { ...item });
      this.$set(this, "stateOriginal", { ...item });
      this.stateDialog = true;
    },
    saveState(formData) {
      const data = {
        ...difference(formData, this.stateOriginal),
        lactation_id: this.lactation.id,
      };
      if (this.state.id) {
        return states.edit(this.state.id, data).then((response) => {
          this.stateDialog = false;
          this.$toast("Zustand aktualisiert");
          this.states = this.states.map((s) => {
            if (s.id != response.data.id) return s;
            return response.data;
          });
        });
      }
      states.store(data).then((response) => {
        this.states = [...this.states, response.data];
        this.$toast("Zustand gespeichert");
        this.stateDialog = false;
      });
    },
    showNewStateDialog() {
      this.state = {};
      this.stateDialog = true;
    },
    today() {
      return new Date().toISOString().slice(0, 10);
    },
  },
};
</script>

<style scoped lang="scss">
.v-data-table {
  &::v-deep .animal__risk-green {
    background-color: rgba(0, 255, 0, 0.2);
  }
  &::v-deep .animal__risk-yellow {
    background-color: rgba(255, 255, 0, 0.2);
  }
  &::v-deep .animal__risk-red {
    background-color: rgba(255, 0, 0, 0.2);
  }
}
</style>
