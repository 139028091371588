const refreshAccessToken = state => {
  return state.api
    .refreshToken(state.refresh_token)
    .then(response => {
      setTokens(state, response.data);
    })
    .catch(e => {
      state.authenticated = false;
      throw e;
    });
};

const setTokens = (state, data) => {
  state.expires_at = new Date().getTime() + data.expires_in * 1000;
  state.access_token = data.access_token;
  state.refresh_token = data.refresh_token;
  if (state.interceptors.response) {
    state.http.interceptors.request.eject(state.interceptors.request);
    state.http.interceptors.response.eject(state.interceptors.response);
  }
  state.interceptors.request = state.http.interceptors.request.use(config => {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${state.access_token}`
    };
    return config;
  });
  state.interceptors.response = state.http.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;
      const errorResponse = error.response;
      if (errorResponse.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        await refreshAccessToken(state);
        state.http.defaults.headers.common.Authorization =
          "Bearer " + state.access_token;
        return state.http(originalRequest);
      }
      return Promise.reject(error);
    }
  );
};

const defaultState = {
  access_token: null,
  api: null,
  authenticated: false,
  expires_at: null,
  http: null,
  interceptors: {},
  refresh_token: null,
  user: null,
};

const actions = {
  setApiAndHttp({ commit }, options) {
    commit("init", options);
  },
  init({ commit, state }) {
    if (state.authenticated) {
      // state.http.defaults.headers.common.Authorization = `Bearer ${state.access_token}`;
      setTokens(state, state);
      return state.api.check().catch((error) => {
        commit("logout");
        throw error;
      });
    } else {
      return new Promise((resolve) => {
        commit("logout");
        resolve(false);
      });
    }
  },
  login({ commit, state }, data) {
    return state.api.login(data).then((response) => {
      commit("setTokens", response.data);
      commit("login", response.data);
      // state.http.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`;

      commit("company/setCompany", response.data.user.company, { root: true });

      return response;
    });
  },
  logout({ commit, state }) {
    return state.api.logout().finally(() => {
      commit("logout");
    });
  },
  register({ state }, data) {
    return state.api.register(data);
  },
  reset({ state }, data) {
    return state.api.reset(data);
  },
};

const mutations = {
  init(state, { http, api }) {
    state.api = api;
    state.http = http;
  },
  login(state, data) {
    // Set user but not their company here
    state.user = { ...data.user, company: undefined };
    state.authenticated = true;
  },
  logout(state) {
    state.expires_at = null;
    state.access_token = null;
    state.refresh_token = null;
    state.authenticated = false;
  },
  setTokens(state, data) {
    setTokens(state, data);
  },
  userUpdate(state, data) {
    state.user = {
      ...state.user,
      ...data,
    };
  },
};

const getters = {
  expired(state) {
    return state.expires_at < new Date().getTime();
  },
  accessToken(state) {
    return state.access_token;
  },
  refreshToken(state) {
    return state.refresh_token;
  },
  authenticated(state) {
    return state.authenticated;
  },
  user(state) {
    return state.user;
  },
};

const auth = {
  namespaced: true,
  state: defaultState,
  actions,
  mutations,
  getters,
};

export default function authPlugin(options) {
  if (!options.http || !options.api)
    throw Error("Auth plugin needs http and api");
  return (store) => {
    // Prevent overriding persisted state
    if (!store.state.auth) {
      store.registerModule("auth", auth);
    }
    store.dispatch("auth/setApiAndHttp", options);
  };
}
