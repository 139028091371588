import axios from "axios";
import { config, http } from "./http";

const res = "auth";

const login = (data) => {
  return http.post(`/${res}/login`, data);
};

const logout = () => {
  return http.post(`/${res}/logout`);
};

const register = (data) => {
  return http.post(`/${res}/register`, data);
};

const reset = (data) => {
  return http.post(`/${res}/reset`, data);
};

const refreshToken = token => {
  return axios.create(config).post(`/${res}/refresh`, { refresh_token: token });
};

const check = () => {
  return http.get("/ping");
};

export const auth = {
  check,
  login,
  logout,
  refreshToken,
  register,
  reset,
};
