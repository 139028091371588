import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import { auth as api, http } from "@/api";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

import authPlugin from "./plugins/auth";

import { animals } from "./animals";
import { app } from "./app";
// import { auth } from "./auth";
import { company } from "./company";
import { lactations } from "./lactations";

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    animals,
    app,
    // auth,
    company,
    lactations,
  },
  plugins: [authPlugin({ api, http }), vuexLocal.plugin],
});
