import { lactations as api } from "@/api";

const defaultState = () => ({
  entities: {},
  ids: [],
});

const actions = {
  init({ commit }) {
    api
      .all()
      .then((response) => {
        commit("setState", response.data);
      })
      .catch((err) => {
        console.error(err.response);
      });
  },
  add({ commit }, lactation) {
    commit("addLactation", lactation);
  },
  edit({ commit }, lactation) {
    api.edit(lactation).then((response) => {
      const data = response.data;
      commit("editLactation", data);
    });
  },
};

const mutations = {
  addLactation(state, lactation) {
    state.entities[lactation.id] = { ...lactation };
    state.ids = [...state.ids, lactation.id];
  },
  editLactation(state, lactation) {
    state.entities[lactation.id] = { ...lactation };
  },
  setState(state, data) {
    state.entities = Object.values(data).reduce((acc, obj) => ({...acc, [obj.id]: obj}), {});
    state.ids = Object.values(data).map((l) => l.id);
  },
  resetState(state) {
    Object.assign(state, defaultState());
  },
};

const getters = {
  all(state) {
    return state.entities;
  },
};

export const lactations = {
  namespaced: true,
  state: defaultState(),
  actions,
  mutations,
  getters,
};
