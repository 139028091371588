<template>
  <v-container style="max-width: 800px;">
    <v-form ref="account" @submit.prevent="submit('account')">
      <v-row>
        <v-col
          class="text-left mx-3 px-0"
          style="border-bottom: 2px solid #AAA;"
        >
          <span class="text-h5">Zugangsdaten</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Email"
            name="optikuh2-email"
            :rules="rules.email"
            v-model="email"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Passwort"
            name="optikuh2-password"
            :rules="rules.password"
            type="password"
            v-model="password"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-show="userDataAltered">
        <v-col>
          <v-text-field
            label="aktuelles Passwort"
            name="optikuh2-password-confirm"
            :rules="rules.passwordConfirm"
            type="password"
            v-model="passwordConfirm"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-show="userDataAltered">
        <v-col>
          <v-btn color="success" type="submit">Speichern</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-form ref="personal" @submit.prevent="submit('personal')">
      <v-row>
        <v-col
          class="text-left mx-3 px-0 mt-5"
          style="border-bottom: 2px solid #AAA;"
        >
          <span class="text-h5">Persönliche Daten</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Ihr vollständiger Name"
            name="optikuh2-name"
            :rules="rules.personalName"
            v-model="personalName"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="userNameAltered">
        <v-col>
          <v-btn color="success" type="submit">Speichern</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-form ref="company" @submit.prevent="submit('company')">
      <v-row>
        <v-col
          class="text-left mx-3 px-0 mt-5"
          style="border-bottom: 2px solid #AAA;"
        >
          <span class="text-h5">Betriebsdaten</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Betriebsname"
            name="optikuh2-company-name"
            :rules="rules.name"
            v-model="name"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Straße/Hsnr."
            name="optikuh2-company-street"
            :rules="rules.street"
            v-model="street"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="flex-grow-0">
          <v-text-field
            label="PLZ"
            name="optikuh2-company-zip"
            :rules="rules.zip"
            v-model="zip"
            style="width: 60px"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Ort"
            name="optikuh2-company-city"
            :rules="rules.city"
            v-model="city"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="companyAltered">
        <v-col>
          <v-btn color="success" type="submit">Speichern</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-form ref="information" @submit.prevent="submit('information')">
      <v-row>
        <v-col
          class="text-left ml-3 px-0 mt-5"
          style="border-bottom: 2px solid #AAA;"
        >
          <span class="text-h5">Leistungsdaten</span>
        </v-col>
        <v-col
          class="text-left mr-3 pl-5 pr-0 mt-5 flex-grow-0"
          style="border-bottom: 2px solid #AAA;"
        >
          <v-select
            @change="changeInformation"
            class="mt-0 pt-0"
            hide-details
            :items="information"
            style="width: 100px;"
            v-model="dataYear"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col @click="checkInformationStatus()">
          <v-text-field
            :disabled="dataYear !== 'new'"
            type="number"
            min="1900"
            max="3000"
            label="Jahr"
            hint="Für welches Jahr diese Zahlen gelten"
            :rules="rules.year"
            v-model.number="year"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col @click="checkInformationStatus()">
          <v-text-field
            :disabled="dataYear !== 'new'"
            type="number"
            min="0"
            max="50000"
            label="Ø Milchleistung/Jahr in kg"
            hint="Wert des letzten Jahres aus dem LKV Bericht"
            :rules="rules.milk_yield"
            v-model.number="milk_yield"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col @click="checkInformationStatus()">
          <v-text-field
            :disabled="dataYear !== 'new'"
            type="number"
            min="0"
            max="10000000"
            label="Ø Zellzahlen Milch in 1000/ml"
            hint="Wert des letzten Jahres"
            :rules="rules.milk_cell_numbers"
            v-model.number="milk_cell_numbers"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col @click="checkInformationStatus()">
          <v-text-field
            :disabled="dataYear !== 'new'"
            type="number"
            min="0"
            max="100"
            label="Totgeburtenrate [%]"
            hint="Wert des letzten Jahres"
            :rules="rules.stillbirth_rate"
            v-model.number="stillbirth_rate"
          ></v-text-field>
        </v-col>
        <v-col @click="checkInformationStatus()">
          <v-text-field
            :disabled="dataYear !== 'new'"
            type="number"
            min="0"
            max="100"
            label="Besamungsindex [%]"
            hint="Wert des letzten Jahres"
            :rules="rules.insemination_index"
            v-model.number="insemination_index"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="dataYear === 'new'">
        <v-col>
          <v-btn color="success" type="submit">Speichern</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar
      bottom
      :color="snackbar.color"
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      transition="slide-y-transition"
      width="300"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "Company",

  data: () => ({
    fields: {
      account: ["email", "password", "passwordConfirm"],
      personal: ["personalName"],
      company: ["name", "street", "zip", "city"],
      information: [
        "milk_yield",
        "milk_cell_numbers",
        "stillbirth_rate",
        "insemination_index",
        "year",
      ],
    },
    snackbar: {
      color: "success",
      message: "",
      show: false,
      timeout: 3000,
    },

    email: "",
    password: "",
    passwordConfirm: "",

    personalName: "",

    name: "",
    street: "",
    zip: "",
    city: "",

    dataYear: "",
    information: [],

    milk_yield: "",
    milk_cell_numbers: "",
    stillbirth_rate: "",
    insemination_index: "",
    year: "",

    rules: {
      email: [
        (v) => !!v || "E-Mail eingeben",
        (v) => /.+@.+\..+/.test(v) || "Korrekte E-Mail eingeben",
      ],
      password: [
        (v) =>
          !v || v.length >= 8 || "Das Passwort muss mind. 8 Zeichen enthalten",
      ],
      passwordConfirm: [
        (v) => !!v || "Aktuelles Passwort eingeben",
        (v) =>
          (v && v.length >= 8) || "Das Passwort muss mind. 8 Zeichen enthalten",
      ],
      personalName: [
        (v) => !!v || "Ihren Namen eingeben",
        (v) =>
          (v && v.length > 0 && v.length < 190) || "Maximal 190 Zeichen lang",
      ],
      name: [
        (v) => !!v || "Name des Betriebes eingeben",
        (v) =>
          (v && v.length > 0 && v.length < 190) || "Maximal 190 Zeichen lang",
      ],
      street: [
        (v) => !!v || "Straße/Hsnr. eingeben",
        (v) =>
          (v && v.length > 0 && v.length < 190) || "Maximal 190 Zeichen lang",
      ],
      zip: [
        (v) => !!v || "Ungültig",
        (v) => (v && v.length == 5) || "Ungültig",
        (v) => (v && Math.round(v) == v) || "Ungültig",
      ],
      city: [
        (v) => !!v || "Ort eingeben",
        (v) =>
          (v && v.length > 0 && v.length < 190) || "Maximal 190 Zeichen lang",
      ],
      milk_yield: [
        (v) => !!v || "Milchmenge eingeben",
        (v) => (v && v > 0 && v < 50000) || "Muss zwischen 0 und 50000 liegen",
      ],
      milk_cell_numbers: [
        (v) => !!v || "Zellzahlen eingeben",
        (v) =>
          (v && v > 0 && v < 10000000) || "Muss zwischen 0 und 10000000 liegen",
      ],
      stillbirth_rate: [
        (v) => !!v || "Totgeburtenrate eingeben",
        (v) => (v && v > 0 && v < 100) || "Muss zwischen 0 und 100 liegen",
      ],
      insemination_index: [
        (v) => !!v || "Besamungsindex eingeben",
        (v) => (v && v > 0 && v < 100) || "Muss zwischen 0 und 100 liegen",
      ],
      year: [
        v => !!v || "Jahr eingeben",
        v => (v && v > 1900 && v <= (new Date().getFullYear())) || `Jahr muss zwischen 1900 und ${new Date().getFullYear()} liegen.`
      ],
    },
  }),

  computed: {
    company() {
      return this.$store.getters["company/company"];
    },
    companyAltered() {
      return (
        this.name !== this.company.name ||
        this.street !== this.company.street ||
        this.zip !== this.company.zip ||
        this.city !== this.company.city
      );
    },
    userData() {
      return this.$store.getters["auth/user"];
    },
    userDataAltered() {
      return this.userData.email !== this.email || this.password !== "";
    },
    userName() {
      return this.$store.getters["auth/user"].name;
    },
    userNameAltered() {
      return this.userName !== this.personalName;
    },
  },

  watch: {
    "company.information.length": {
      handler() {
        this.updateData();
      },
    },
    company() {
      this.updateData();
    },
    userData() {
      this.updateData();
    },
    userName() {
      this.updateData();
    },
  },

  mounted() {
    this.updateData();
  },

  methods: {
    updateData() {
      this.email = this.userData.email;
      this.personalName = this.userName;
      ["name", "street", "zip", "city"].map(
        (key) => (this[key] = this.company[key])
      );

      this.information = [{ text: "Neu", value: "new" }].concat(
        this.company.information.map((info) => {
          // const dataYear = new Date(info.created_at).getFullYear();
          return {
            text: info.year,
            value: info.year,
          };
        })
      );

      const latestDate = this.company.information.reduce((acc, info) => {
        return info.created_at > acc ? info.created_at : acc;
        // const dataYear = new Date(info.created_at).getFullYear();
        // return dataYear > acc ? dataYear : acc;
      }, "");
      this.dataYear = latestDate;
      this.changeInformation(latestDate);
    },
    changeInformation(ev) {
      this.setInformationFor(ev);
    },
    checkInformationStatus() {
      if (this.dataYear === "new") return;
      this.$set(this, "snackbar", {
        color: "error",
        message:
          "Bitte wählen Sie 'NEU' unter Leistungsdaten aus um neue Werte einzutragen",
        timeout: 6000,
        show: true,
      });
    },
    setInformationFor(dataYear) {
      const fields = [
        "milk_yield",
        "milk_cell_numbers",
        "stillbirth_rate",
        "insemination_index",
        "year",
      ];
      if (dataYear === "new") {
        fields.map((key) => (this[key] = ""));
        return;
      }
      const information = this.company.information.find((info) => {
        return info.created_at == dataYear;
      });
      if (!information) return;
      fields.map((key) => (this[key] = information[key]));
    },
    submit(type) {
      if (!this.$refs[type].validate()) return;

      const data = this.fields[type].reduce(
        (acc, key) => ({ ...acc, [key]: this[key] || undefined }),
        {}
      );

      this.$store
        .dispatch("company/update", {
          ...data,
          type,
        })
        .then(() => {
          this.passwordConfirm = "";
          this.$set(this, "snackbar", {
            color: "success",
            message: "Daten geändert",
            timeout: 3000,
            show: true,
          });
        })
        .catch((error) => {
          // The give confirm password was invalid
          if (error.response.status === 403) {
            this.$set(this, "snackbar", {
              color: "error",
              message: "Das aktuelle Passwort stimmt nicht überein",
              timeout: 3000,
              show: true,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input ::v-deep .v-label--is-disabled {
  color: rgba(0, 0, 0, 0.6) !important;
}
.theme--light.v-input--is-disabled {
  ::v-deep input,
  ::v-deep textfield {
    color: rgba(0, 0, 0, 0.6) !important;
  }
}
</style>
