export default {
  // thresholds: [60, 200], // risk coloring thresholds
  thresholds: [5.7, 6.7],
  validation: {
    BHB: {
      min: 0.05,
      max: 15,
      step: 0.01,
    },
    body_condition_score: {
      min: 1,
      max: 5,
      step: 0.25,
    },
    calcium: {
      min: 0.5,
      max: 5,
      step: 0.1,
    },
    fat_content: {
      min: 0.5,
      max: 10,
      step: 0.1,
    },
    glucose: {
      min: 1,
      max: 15,
      step: 0.1,
    },
    IGF1: {
      min: 0.5,
      max: 1500,
      step: 0.1,
    },
    insulin: {
      min: 0.05,
      max: 200,
      step: 0.01,
    },
    milk_per_day: {
      min: 0,
      max: 50,
      step: 1,
    },
    NEFA: {
      min: 1,
      max: 5000,
      step: 0.01,
    },
    protein_content: {
      min: 0.5,
      max: 10,
      step: 0.1,
    },
    risk: {
      min: 0,
      max: 65535,
    },
    risk_100: {
      min: 0,
      max: 65535,
    }
  }
};
