<template>
  <div>
    <v-navigation-drawer
      app
      clipped
      id="app__navigation-drawer"
      left
      v-model="drawer"
      width="250"
    >
      <v-list nav class="py-0 full-height" id="app__navigation-drawer_list">
        <!-- System ICaaS -->
        <v-list-item class="px-0" link dark :to="{ name: 'Home' }">
          <v-list-item-avatar>
            <img
              alt="OQ2"
              :src="require('@/assets/logo.svg')"
              style="background: white;"
            />
          </v-list-item-avatar>
          <v-list-item-content class="py-0">
            <v-list-item-title style="font-size: 18px !important; line-height: 1.4; letter-spacing: 0.1em;">Livestock<br>Management</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link dark :to="{ name: 'Company' }" v-if="isAuthentiated">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Betrieb/Konto</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link dark :to="{ name: 'Animals' }" v-if="isAuthentiated">
          <v-list-item-icon>
            <v-icon>mdi-cow</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Tiere</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link dark :to="{ name: 'Warning' }" v-if="isAuthentiated">
          <v-list-item-icon>
            <v-icon>mdi-alert</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Warnung</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link dark :to="{ name: 'Import' }" v-if="isAuthentiated">
          <v-list-item-icon>
            <v-icon>$vuetify.icons.udder</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Herde Import</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider color="white" v-if="isAuthentiated" />

        <v-list-item link dark :to="{ name: 'Datenschutz' }">
          <v-list-item-icon>
            <v-icon>mdi-shield-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Datenschutz</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link dark :to="{ name: 'Nutzungsbedingungen' }">
          <v-list-item-icon>
            <v-icon>mdi-file-document-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>AGBs</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link dark :to="{ name: 'Impressum' }">
          <v-list-item-icon>
            <v-icon>mdi-card-account-details</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Impressum</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider color="white" />

        <v-list-item link dark v-if="isAuthentiated" @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link dark :to="{ name: 'Login' }" v-if="!isAuthentiated">
          <v-list-item-icon>
            <v-icon>mdi-login</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Anmelden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider color="white" />

        <v-list-item>
          <v-list-item-content>
            <v-img :src="require('@/assets/optikuh2.png')"></v-img>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      fixed
      app
      color="primary"
      dark
      clipped-right
      v-if="$vuetify.breakpoint.mdAndDown"
    >
      <v-app-bar-nav-icon @click.stop="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ $route.meta.title || "" }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-img
        @click="$router.push({ name: 'Home' })"
        eager
        :src="require('@/assets/logo.svg')"
        style="background: white; border-radius: 3em;"
        max-width="2.5em"
        max-height="2.5em"
      ></v-img>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: null,
  }),

  computed: {
    isAuthentiated() {
      return this.$store.getters["auth/authenticated"];
    },
  },

  methods: {
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => {
           ['animals', 'company', 'lactations'].forEach(mod => this.$store.commit(`${mod}/resetState`));
          this.$router.push({ name: "Login" })
        });
    },
  },
};
</script>

<style>
#app__navigation-drawer {
  background: linear-gradient(
    90deg,
    var(--v-primary-base) 0px,
    var(--v-primary-base) 56px,
    var(--v-primary-darken1) 250px
  );
}

#app__navigation-drawer_list {
  padding-bottom: 3.5rem !important;
  position: relative;
}
</style>

<style scoped>
.v-list-item__title {
  font-size: 20px !important;
}

.v-list .v-list-item--active,
.v-list .v-list-item--active .v-icon {
  color: white !important;
}
</style>
