<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        autocomplete="off"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        readonly
        v-bind="$attrs"
        :value="computedDateFormatted"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      :allowedDates="allowedDates"
      @input="menu = false"
      no-title
      :show-current="false"
      v-model="date"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "v-date-picker-menu",

  props: {
    allowedDates: {
      type: Function,
      default: null,
    },
    format: {
      type: String,
      default: "yyyy-MM-dd",
    },
    value: {
      type: String,
      default: undefined,
    },
  },

  data: () => ({
    date: undefined,
    menu: false,
  }),

  watch: {
    date(val) {
      this.$emit("input", val);
    },
    value(val) {
      if (val != this.date) {
        this.date = val;
      }
    }
  },

  computed: {
    computedDateFormatted() {
      if (!this.date) return null;
      return this.$date.format(this.date, this.format);
    },
  },

  mounted() {
    if (this.value) {
      this.date = this.value;
    }
  }
};
</script>
