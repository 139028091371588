<template>
  <v-snackbar
    :absolute="absolute"
    :bottom="bottom"
    :centered="centered"
    :color="color"
    :content-class="contentClass"
    :elevation="elevation"
    @input="value = $event"
    :left="left"
    :right="right"
    :rounded="rounded"
    :shaped="shaped"
    :tag="tag"
    :tile="tile"
    :timeout="timeout"
    :top="top"
    :value="value"
  >
    {{ text }}

    <template v-if="dismissable" v-slot:action="{ attrs }">
      <v-btn
        @click="value = false"
        color="error"
        elevation="0"
        fab
        v-bind="attrs"
        x-small
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { emitter } from "./emitter";

export default {
  name: "Plugin_Snackbar",

  data: () => ({
    absolute: false,
    bottom: true,
    centered: false,
    color: "green darken-2",
    contentClass: undefined,
    dismissable: false,
    elevation: undefined,
    left: false,
    right: true,
    rounded: undefined,
    shaped: false,
    tag: "div",
    text: "",
    tile: false,
    timeout: 1000,
    top: false,
    value: false,
    default: {
      absolute: false,
      bottom: true,
      centered: false,
      color: "green darken-2",
      contentClass: undefined,
      dismissable: false,
      elevation: undefined,
      left: false,
      right: true,
      rounded: undefined,
      shaped: false,
      tag: "div",
      text: "",
      tile: false,
      timeout: 1500,
      top: false,
      value: false,
    },
  }),

  mounted() {
    emitter.on("plugin::toast-show", (e) => {
      Object.assign(this, this.default, e);
      this.$emit("input", true);
      this.value = true;
    });
    emitter.on("plugin::toast-close", () => {
      this.value = false;
    });
  },

  methods: {
    show(msg) {
      this.text = msg;
    },
  },
};
</script>
