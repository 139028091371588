import Vue from "vue";
import VueRouter from "vue-router";

import Animal from "@/views/Animal.vue";
import AnimalCreate from "@/views/AnimalCreate.vue";
import Animals from "@/views/Animals.vue";
import Company from "@/views/Company.vue";
import Home from "@/views/Home.vue";
import Import from "@/views/Import.vue";
import Lactation from "@/views/Lactation.vue";
import Warning from "@/views/Warning.vue";

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";

import Datenschutz from "@/views/legal/Datenschutz.vue";
import Nutzungsbedingungen from "@/views/legal/Nutzungsbedingungen.vue";
import Impressum from "@/views/legal/Impressum.vue";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/Start",
  },

  {
    path: "/Start",
    name: "Home",
    component: Home,
    meta: {
      title: "RUDI",
      guest: false,
    },
  },

  {
    path: "/Betrieb",
    name: "Company",
    component: Company,
    meta: {
      title: "Betrieb",
      guest: false,
    },
  },

  {
    path: "/Tier/:id",
    name: "Animal",
    component: Animal,
    meta: {
      title: "Tierübersicht",
      guest: false,
      bottom: [
        {
          icon: "mdi-cow",
          text: "Daten",
          value: 0,
        },
        {
          icon: "$vuetify.icons.udder",
          text: "Laktationen",
          value: 1,
        },
        {
          icon: "$vuetify.icons.udder",
          text: "Hinzufügen",
          value: 2,
        },
      ],
    },
  },

  {
    path: "/Tiere/Neu",
    name: "Animals_Create",
    component: AnimalCreate,
    meta: {
      title: "Neues Tier eintragen",
      guest: false,
    },
  },

  {
    path: "/Tiere",
    name: "Animals",
    component: Animals,
    meta: {
      title: "Tiere",
      guest: false,
    },
  },

  {
    path: "/Laktation/:id",
    name: "Lactation",
    component: Lactation,
    meta: {
      title: "Laktation",
      guest: false,
    },
  },

  {
    path: "/Warnung",
    name: "Warning",
    component: Warning,
    meta: {
      title: "Warnung",
      guest: false,
    },
  },

  {
    path: "/Import",
    name: "Import",
    component: Import,
    meta: {
      title: "Herde Import",
      guest: false,
    },
  },

  // AUTH

  {
    path: "/Auth/Login",
    name: "Login",
    component: Login,
    meta: {
      guest: true,
    },
  },

  {
    path: "/Auth/Register",
    name: "Register",
    component: Register,
    meta: {
      guest: true,
    },
  },

  {
    path: "/Auth/ResetPassword",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      guest: true,
    },
  },

  {
    path: "/Datenschutz",
    name: "Datenschutz",
    component: Datenschutz,
    meta: {
      title: "Datenschutz",
    },
  },

  {
    path: "/Nutzungsbedingungen",
    name: "Nutzungsbedingungen",
    component: Nutzungsbedingungen,
    meta: {
      title: "Nutzungsbedingungen",
    },
  },

  {
    path: "/Impressum",
    name: "Impressum",
    component: Impressum,
    meta: {
      title: "Impressum",
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.bottom)) {
    const items = to.matched.find((record) => record.meta.bottom).meta.bottom;
    store.state.app.bottomNav = {
      show: true,
      values: items,
      value: 0,
    };
  } else {
    store.state.app.bottomNav = {
      show: false,
      values: [],
      value: 0,
    };
  }
  if (to.matched.some((record) => record.meta.guest === false)) {
    if (!store.state.auth.authenticated) {
      next({
        name: "Login",
        // params: { nextUrl: to.fullPath }
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest === true)) {
    if (!store.state.auth.authenticated) {
      next();
    } else {
      next({ name: "Home" });
    }
  } else {
    next();
  }
});

export default router;
