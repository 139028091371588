<template>
  <v-app>
    <app-drawer />
    <v-main>
      <v-container fluid class="app--content">
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-container>
    </v-main>
    <importer-main-dialog />
    <plugin-toast />
    <v-fade-transition>
      <v-bottom-navigation fixed v-if="navigationShow" v-model="navigation">
        <v-btn :key="navValue.value" v-for="navValue in navigationValues" :value="navValue.value">
          <span>{{ navValue.text }}</span>

          <v-icon color="#333">{{ navValue.icon }}</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-fade-transition>
  </v-app>
</template>

<script>
import Drawer from "@/components/app/Drawer";

export default {
  name: "App",

  components: {
    "app-drawer": Drawer,
  },

  data: () => ({
    //
  }),

  computed: {
    navigation: {
      get() {
        return this.$store.getters["app/bottomNavValue"];
      },
      set(val) {
        this.$store.commit("app/setBottomNavValue", val);
      },
    },
    navigationValues() {
      return this.$store.getters["app/bottomNavValues"];
    },
    navigationShow() {
      return this.$store.getters["app/bottomNavShow"];
    },
  },

  watch: {
    '$store.state.auth.authenticated': {
        immediate: true,
        handler(val) {
           if (!val) {
             this.$store.commit('auth/logout');
             this.$router.push({ name: "Login" });
           }
        }
     }
  },

  created() {
    this.$store
      .dispatch("auth/init")
      .then((authenticated) => {
        if (!authenticated) return;
        // this.$store.dispatch("companies/init");
        this.$store.dispatch("animals/init");
        this.$store.dispatch("lactations/init");
      })
      .catch((error) => {
        console.log(error);
        this.$router.push({ name: "Login" });
      });
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
