import { http } from "./http";

const res = "lactations";

const all = () => {
  return http.get(`/${res}`);
};

const store = (data) => {
  return http.post(`/${res}`, data);
};

export const lactations = {
  all,
  store,
};
