import mitt from "mitt";

const emitter = mitt();

export { emitter };

export default {
  install(Vue) {
    Vue.prototype.$emitter = emitter;
  }
};