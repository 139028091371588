<template>
  <div>
    <v-btn
      @click="$importer.mainDialog = true"
      color="success"
      elevation="0"
      >importieren</v-btn
    >
    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="imports"
      :options.sync="options"
      :server-items-length="totalImports"
      style="position: relative;"
    >
      <template v-slot:[`item.email`]="{ item }">
        {{ item.user.email }}
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        {{ $date.format(item.created_at, "dd.MM.yyyy HH:ii") }}
      </template>

      <template v-slot:[`item.statistics`]="{ item }">
        <template v-for="(stat, key) in item.statistics">
          <span :key="`${item.id}-${key}`" v-if="key != 'error'">
            ( {{ stat.imported.length }} /
            {{ stat.imported.length + stat.unimported.length }} )
          </span>
        </template>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <template v-if="item.statistics">
          <v-icon color="error" v-if="item.statistics.error">mdi-close</v-icon>
          <v-icon
            color="success"
            v-else-if="item.processing_finished_at !== null"
            >mdi-check-bold</v-icon
          >
        </template>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn @click="api.download(item.id)" color="success" icon>
          <v-icon>
            mdi-download
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { imports as api } from "@/api";

export default {
  name: "Imports",

  data: () => ({
    api,
    imports: [],
    loading: true,
    headers: [
      { text: "ID", value: "id", width: 100, sortable: false },
      { text: "Erstellt", value: "created_at", sortable: false },
      { text: "Zeilen importiert", value: "statistics", sortable: false },
      { text: "Status", value: "status", width: 80, sortable: false },
      { text: " ", value: "action", width: 80, sortable: false },
    ],
    options: {},
    totalImports: 0,
  }),

  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },

  mounted() {
    this.$root.$on("importer::importFinished", this.getData);
  },

  beforeDestroy() {
    this.$root.$off("importer::importFinished", this.getData);
  },

  methods: {
    getData() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      api
        .all({ page, per_page: itemsPerPage })
        .then((response) => {
          console.log(response);
          const data = response.data;
          this.totalImports = data.total;
          this.imports = data.data;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped lang="scss">
.data-table__footer__button {
  position: absolute;
  left: 10px;
  bottom: 10px;
}
</style>
