import { http } from "./http";

const res = "states";

const edit = (id, data) => {
  return http.post(`/${res}/${id}`, data);
};

const show = id => {
  return http.get(`/${res}/${id}`);
};

const store = (data) => {
  return http.post(`/${res}`, data);
};

export const states = {
  edit,
  show,
  store,
};
