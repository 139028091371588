var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.$router.push({ name: 'Animals_Create' })}}},[_vm._v(" Neues Tier eintragen ")])],1),_c('v-col',{staticClass:"flex-grow-0"},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","elevation":"0","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-help ")])],1)]}}]),model:{value:(_vm.helpDialog),callback:function ($$v) {_vm.helpDialog=$$v},expression:"helpDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 lighten-2"},[_vm._v(" RuDI ")]),_c('v-card-text',{staticStyle:{"text-align":"left"}},[_vm._v(" RuDI ist ein Risiko- und Datenindex zur Einschätzung des Ketoserisikos."),_c('br'),_vm._v(" Bei RuDI ≥ 6,7 weist ein klinisch gesundes Tier ein hohes Risiko auf, eine klinische oder subklinische Ketose zu entwickeln."),_c('br'),_vm._v(" Die Risikobewertung wird optisch wie folgt dargestellt:"),_c('br'),_vm._v(" rot = hohes Risiko"),_c('br'),_vm._v(" grün = niedriges Risiko"),_c('br')]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.helpDialog = false}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"item-class":_vm.compileRowClasses,"item-key":"id","items":_vm.animals,"search":_vm.search},on:{"click:row":_vm.gotoAnimal},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Filter"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.risk_early",fn:function(ref){
var item = ref.item;
return [(item.risk_early)?_c('span',[_vm._v(" "+_vm._s(item.risk_early / 10)+" ")]):(item.latest_lactation_id)?_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openModal(item)}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1):_vm._e()]}},{key:"item.risk_100",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.risk_100 ? item.risk_100 / 10 : "")+" ")]}}],null,true)}),_c('state-dialog',{attrs:{"input":_vm.state},on:{"save":_vm.saveState},model:{value:(_vm.stateDialog),callback:function ($$v) {_vm.stateDialog=$$v},expression:"stateDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }