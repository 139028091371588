<template>
  <v-card elevation="0">
    <v-tabs-items :value="navigation">
      <v-tab-item eager>
        <edit-animal :animal="animal" />
      </v-tab-item>
      <v-tab-item>
        <v-data-table
          :headers="headers"
          :item-class="compileRowClasses"
          :items="lactations"
          item-key="id"
          class="elevation-1"
          @click:row="gotoLactation"
        >
          <template v-slot:[`item.risk_early`]="{ item }">
            {{ item.risk_early ? item.risk_early / 10 : "" }}
          </template>

          <template v-slot:[`item.risk_100`]="{ item }">
            {{ item.risk_100 ? item.risk_100 / 10 : "" }}
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col
                class="text-left mx-3 px-0 mb-5 pb-0"
                style="border-bottom: 2px solid #AAA;"
              >
                <span class="text-h5">Laktationszyklus hinzufügen</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-date-picker-menu
                  :allowedDates="(val) => val <= today"
                  format="dd.MM.yyyy"
                  label="Kalbedatum*"
                  prepend-icon="mdi-calendar"
                  :rules="[(v) => !!v || 'Wert eintragen!']"
                  v-model="lactation.calving"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-date-picker-menu
                  :allowedDates="(val) => val <= today"
                  format="dd.MM.yyyy"
                  label="Trockenstelldatum"
                  prepend-icon="mdi-calendar"
                  v-model="lactation.drying_off_date"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-date-picker-menu
                  :allowedDates="(val) => val <= today"
                  format="dd.MM.yyyy"
                  label="Besamungsdatum"
                  prepend-icon="mdi-calendar"
                  v-model="lactation.insemination_date"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-btn @click="saveLactation()" color="success">
                  Speichern
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { lactations } from "@/api";
import EditAnimal from "../components/EditAnimal.vue";
import VDatePickerMenu from "@/components/VDatePickerMenu.vue";

export default {
  name: "Animal",

  components: {
    EditAnimal,
    VDatePickerMenu,
  },

  data: () => ({
    headers: [
      { text: "#", value: "counter", width: 60 },
      { text: "Kalbedatum", value: "calving", width: 100 },
      { text: "Calcium [mmol/L]", value: "calcium", width: 120 },
      { text: "BHB [mmol/L]", value: "BHB", width: 120 },
      { text: "NEFA [µmol/L]", value: "NEFA", width: 120 },
      { text: "IGF-1 [ng/mL]", value: "IGF1", width: 120 },
      { text: "RUDI", value: "risk_early", width: 70 },
      { text: "Milchmenge [kg/Tag]", value: "milk_per_day", width: 140 },
      { text: "Fettgehalt [%]", value: "fat_content", width: 120 },
      { text: "Proteingehalt [%]", value: "protein_content", width: 120 },
      // { text: "Trockenstellung", value: "drying_off_date", width: 160 },
      // { text: "Besamung", value: "insemination_date", width: 160 },
      // { text: "BCS", value: "body_condition_score", width: 100 },
      { text: "Glukose [mmol/L]", value: "glucose", width: 120 },
      { text: "Insulin [µlU/ml]", value: "insulin", width: 100 },
    ],
    lactation: {},
    menu: {},
    search: "",
  }),

  computed: {
    animal() {
      return this.$store.getters["animals/all"][this.$route.params.id];
    },
    lactations() {
      const all = this.$store.getters["lactations/all"];
      return Object.values(all).filter(
        (l) => l.animal_id == this.$route.params.id
      );
    },
    navigation() {
      return this.$store.getters["app/bottomNavValue"];
    },
    navigationValues() {
      return [
        {
          icon: "mdi-cow",
          text: "Daten",
          value: 0,
        },
        {
          icon: "$vuetify.icons.udder",
          text: "Laktationen",
          value: 1,
        },
        {
          icon: "$vuetify.icons.udder",
          text: "Hinzufügen",
          value: 2,
        },
      ];
    },
    stateFields() {
      return this.headers
        .map((h) => h.value)
        .filter((v) => !["id", "counter", "calving", "risk"].includes(v));
    },
    today() {
      return new Date().toISOString().slice(0, 10);
    },
  },

  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     vm.$store.commit("app/setBottomNavValues", vm.navigationValues);
  //     vm.$store.commit("app/showBottomNav");
  //   });
  // },

  // beforeRouteLeave(to, from, next) {
  //   this.$store.commit("app/hideBottomNav");
  //   this.$store.commit("app/setBottomNavValue", 0);
  //   next();
  // },

  methods: {
    compileRowClasses(item) {
      if (item.risk == null) {
        return "";
      } else if (item.risk_early / 10 < this.$config.thresholds[0]) {
        return "animal__risk-green";
      } else if (item.risk_early / 10 < this.$config.thresholds[1]) {
        return "animal__risk-yellow";
      }
      return "animal__risk-red";
    },
    gotoLactation(item) {
      this.$router.push({ name: "Lactation", params: { id: item.id } });
    },
    saveLactation() {
      if (!this.$refs.form.validate()) return;
      lactations
        .store({ ...this.lactation, animal_id: this.animal.id })
        .then((response) => {
          console.log(response);
          this.$store.dispatch("lactations/add", response.data);
          this.$refs.form.reset();
          this.dialog = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table
  ::v-deep
  .v-data-table__mobile-table-row
  > .v-data-table__mobile-row:last-child {
  border-width: 2px !important;
  border-color: rgba(0, 0, 0, 0.5) !important;
}

.v-data-table ::v-deep .v-data-table__mobile-table-row {
  &.animal__risk-green {
    display: block !important;
    border-left: 6px solid rgba(0, 255, 0, 0.7);
    background: transparent !important;
  }

  &.animal__risk-yellow {
    display: block !important;
    border-left: 6px solid rgba(255, 255, 0, 0.7);
    background: transparent !important;
  }

  &.animal__risk-red {
    display: block !important;
    border-left: 6px solid rgba(255, 0, 0, 0.7);
    background: transparent !important;
  }

  & > .v-data-table__mobile-row:last-child {
    border-width: 2px !important;
    border-color: rgba(0, 0, 0, 0.5) !important;
  }
}

.v-data-table {
  &::v-deep .animal__risk-green {
    background-color: rgba(0, 255, 0, 0.2) !important;
  }
  &::v-deep .animal__risk-yellow {
    background-color: rgba(255, 255, 0, 0.2) !important;
  }
  &::v-deep .animal__risk-red {
    background-color: rgba(255, 0, 0, 0.2) !important;
  }
}
</style>
