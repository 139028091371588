<template>
  <v-container class="pa-0" fluid>
    <v-form ref="form" @submit.prevent="register()">
      <v-card max-width="350" style="margin: auto">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Registrieren</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container style="max-width: 800px;">
            <v-row>
              <v-col
                class="text-left mx-3 px-0"
                style="border-bottom: 2px solid #AAA;"
              >
                <span class="text-h5">Zugangsdaten</span>
              </v-col>
            </v-row>
            <v-row style="display: none;">
              <v-col>
                <v-text-field
                  label="Email"
                  name="email"
                  v-model="hemail"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Email"
                  name="optikuh2-email"
                  :rules="rules.email"
                  v-model="email"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Passwort"
                  name="optikuh2-password"
                  :rules="rules.password"
                  type="password"
                  v-model="password"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="text-left mx-3 px-0 mt-5"
                style="border-bottom: 2px solid #AAA;"
              >
                <span class="text-h5">Persönliche Daten</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Ihr vollständiger Name"
                  name="optikuh2-name"
                  :rules="rules.personalName"
                  v-model="personalName"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="text-left mx-3 px-0"
                style="border-bottom: 2px solid #AAA;"
              >
                <span class="text-h5">Betriebsdaten</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Betriebsname"
                  name="optikuh2-company-name"
                  :rules="rules.name"
                  v-model="name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Straße/Hsnr."
                  name="optikuh2-company-street"
                  :rules="rules.street"
                  v-model="street"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="flex-grow-0">
                <v-text-field
                  label="PLZ"
                  name="optikuh2-company-zip"
                  :rules="rules.zip"
                  v-model="zip"
                  style="width: 60px"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Ort"
                  name="optikuh2-company-city"
                  :rules="rules.city"
                  v-model="city"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-btn color="success" @click="register()">Registrieren</v-btn>
        </v-card-text>
      </v-card>
    </v-form>
    <v-snackbar
      absolute
      bottom
      color="success"
      v-model="dialog"
      timeout="10000"
      transition="slide-y-transition"
      width="300"
    >
      Erfolgreich angemeldet. Sie erhalten eine Email mit weiteren Schritten.
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "Register",

  data: () => ({
    dialog: false,

    hemail: "",
    email: "",
    password: "",
    personalName: "",
    name: "",
    street: "",
    zip: "",
    city: "",

    rules: {
      email: [
        (v) => !!v || "E-Mail eingeben",
        (v) => /.+@.+\..+/.test(v) || "Korrekte E-Mail eingeben",
      ],
      password: [
        (v) => !!v || "Passwort eingeben",
        (v) =>
          (v && v.length >= 8) || "Das Passwort muss mind. 8 Zeichen enthalten",
      ],
      personalName: [
        (v) => !!v || "Ihren Namen eingeben",
        (v) =>
          (v && v.length > 0 && v.length < 190) || "Maximal 190 Zeichen lang",
      ],
      name: [
        (v) => !!v || "Name des Betriebes eingeben",
        (v) =>
          (v && v.length > 0 && v.length < 190) || "Maximal 190 Zeichen lang",
      ],
      street: [
        (v) => !!v || "Straße/Hsnr. eingeben",
        (v) =>
          (v && v.length > 0 && v.length < 190) || "Maximal 190 Zeichen lang",
      ],
      zip: [
        (v) => !!v || "Ungültig",
        (v) => (v && v.length == 5) || "Ungültig",
        (v) => (v && Math.round(v) == v) || "Ungültig",
      ],
      city: [
        (v) => !!v || "Ort eingeben",
        (v) =>
          (v && v.length > 0 && v.length < 190) || "Maximal 190 Zeichen lang",
      ],
    },
  }),

  methods: {
    register() {
      if (!this.$refs.form.validate()) return;
      this.$store
        .dispatch(
          "auth/register",
          Object.keys(this.rules).reduce((a, o) => ({ ...a, [o]: this[o] }), {})
        )
        .then(() => {
          this.dialog = true;
          this.$refs.form.reset();
        });
    },
  },
};
</script>
